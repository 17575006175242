html {
  font-size: 16px;
}

body {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-family: 'Fira Sans', sans-serif;
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 3rem;
  margin: 1.5rem 0 0 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 3rem;
  margin: 0;
}

h4 {
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.5rem;
  margin: 0;
}

h4 span {
  font-style: normal;
  font-weight: 300;
}

a {
  border-bottom: 1px solid #e8ebef;
  color: #343d4c;
  text-decoration: none;
}

.offline a {
  color: #000;
}

p {
  margin: 1.5rem 0;
}

article {
  margin: 3rem auto;
  max-width: 37rem;
  padding: 0 9rem;
}

.smear {
  background: linear-gradient(90deg, rgba(52, 61, 76, 1) 6rem, rgba(68, 70, 61, 1) 100%);
  transition: ease-in-out filter 1s;
}

.offline .smear {
  filter: grayscale(100%);
}

.icm {
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  object-fit: cover;
  object-position: 50% 45%;
  transition: ease-in-out filter 1s;
}

.offline .icm {
  filter: grayscale(100%);
}

header dl {
  margin: 0;
}

header dt {
  display: none;
}

header dd {
  display: inline;
  line-height: 1.5;
  margin: 0;
}

header dd:first-of-type::after {
  content: ' · ';
  margin: 0 0.25rem;
}

footer {
  border-top: 1px solid #e8ebef;
  font-size: 0.875rem;
  margin: 0 0 3rem;
}

ul {
  margin: 0;
}

@media (max-width: 48rem) {
  article {
    padding: 0 4.5rem;
  }
}

@media (max-width: 35rem) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  article {
    padding: 0 1.5rem;
    margin: 1.5rem auto;
  }

  .skills-list {
    column-count: 1;
  }
}

/* 

♻️ Please consider the environment before printing my résumé. 

🖨 Let's make it printer friendly, for the folks who prefer a hard copy. 

*/
@media print {
  h1 {
    margin-top: 0;
    padding-top: 0;
  }

  article {
    padding: 0;
    margin: 0;
  }

  @page {
    margin: 1in 1.25in;
  }

  a {
    color: #000;
    border: 0;
  }

  footer,
  .smear {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background: linear-gradient(135deg, rgba(52, 61, 76, 1) 6rem, rgba(68, 70, 61, 1) 100%);
    color: #fff;
  }

  .smear {
    background: transparent;
  }

  a {
    border-bottom: 1px solid #343d4c;
    color: #d09a8d;
  }
}
